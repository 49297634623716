import Vue from 'vue'
import debounce from 'lodash/debounce'
import pickBy from 'lodash/pickBy'
import cloneDeep from 'lodash/cloneDeep'
import RoleSelect from '../RoleSelect/RoleSelect.vue'
import { mapGetters, mapState } from 'vuex'
import { RootState } from '@/store'
import VacancyRoleCategory from '@/models-ts/vacancies/VacancyRoleCategory'
import { BROWSE_VACANCIES_BY_ROLE, VACANCIES } from '@/constants/routes'

export default Vue.extend<any, any, any, any>({
  name: 'lx-jobs-filter-modal',
  components: {
    RoleSelect
  },
  data () {
    return {
      role: null,
    }
  },
  computed: {
    ...mapState<RootState>({
      rolesLoaded: (state: RootState) => state.vacancyRoles.roles.isLoaded,
      roles: (state: RootState) => state.vacancyRoles.roles.value || [],
    }),
    ...mapGetters({
      getRoleByURL: 'vacancyRoles/getRoleByURL',
    }),
    selectedRole () {
      if (this.role && this.rolesLoaded) {
        for (const category of this.roles as VacancyRoleCategory[]) {
          const role = category.primaryRoles.find(r => r.id === this.role)
          if (role) return role
        }
      }
    },
    search () {
      return this.$route.query.search
    },
    hasFilter () {
      return this.search || this.role
    },
    tags () {
      const roleTag = this.selectedRole?.name
        ? [{
          text: `Role: ${this.selectedRole.name}`,
          type: 'role',
        }]
        : []

      return roleTag
    },
  },
  watch: {
    rolesLoaded () {
      this.initRole()
    },
  },
  mounted () {
    this.initFiltersFromQuery()
  },
  methods: {
    initRole () {
      if (this.$route.params.role) {
        this.role = this.getRoleByURL(this.$route.params.role)?.id
      } else {
        this.role = Number(this.$route.query.role)
      }
    },
    initFiltersFromQuery () {
      this.initRole()
    },
    onTagDelete ({ tag }: { tag: any }) {
      switch (tag.type) {
        case 'role': {
          this.role = null
        }
      }
      this.debouncedMTQ()
    },
    debouncedMTQ: debounce(function (this: any) {
      this.mapToQuery()
    }, 500),
    onClickReset () {
      this.mapToQuery(true)
      this.$emit('close')
    },
    mapToQuery (reset?: boolean) {
      let filters = {}
      if (!reset) {
        filters = cloneDeep(pickBy({
          ...this.$route.query,
          role: this.role,
        }, Boolean))
      }
      let name = this.$route.name
      let params = this.$route.params
      if ((name === BROWSE_VACANCIES_BY_ROLE && this.selectedRole?.url !== this.$route.params.role) || reset) {
        name = VACANCIES
        params = {}
      }
      this.$router.replace({
        name,
        params,
        query: filters,
      }).catch(() => {})
    },
  }
})
